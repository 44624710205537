<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept type="search" label="LBLDEPT" name="deptCd" :isFirstValue="false" v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="검진기간"
            name="period"
            defaultStart="-24M"
            defaultEnd="12M"
            v-model="period1"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="검진예정기간"
            name="period"
            v-model="period2"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-checkbox
            :isFlag="true"
            label="1주일 내 도래 대상"
            name="datediff"
            v-model="searchParam.datediff"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="사용자별 특수검진결과 현황"
      tableId="table"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      @linkClick="linkClick"
      :columnSetting="false"
    >
      <!-- 버튼 영역 -->
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'checkupComeDate'">
          <q-chip
            v-if="props.row.checkupComeDate"
            outline square
            :color="setTagColor(props.row.checkupComeDate)"
            :class="props.row.checkupComeDate ==='4' ? 'blinking' : null"
            text-color="white">
            {{setTagName(props.row)}}
          </q-chip>
        </template>
      </template>
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'check-up-status',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '대상자명',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
            type: 'link'
          },
          {
            name: 'recentCheckupDate',
            field: 'recentCheckupDate',
            label: '최근검진일',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'checkupCycleName',
            field: 'checkupCycleName',
            label: '검진주기',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'checkupScheduleDate',
            field: 'checkupScheduleDate',
            label: '검진 예정일',
            style: 'width: 120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'checkupComeDate',
            field: 'checkupComeDate',
            label: '검진일 도래',
            align: 'center',
            type: 'custom',
            style: 'width: 120px',
            sortable: true,
          },
        ],
        data: [],
        merge: [
          { index: 0, colName: 'mergeGroup' },
          { index: 1, colName: 'mergeGroup' },
          { index: 2, colName: 'mergeGroup' },
        ]
      },
      searchParam: {
        plantCd: '',
        deptCd: '',
        startYmd1: '',
        endYmd1: '',
        startYmd2: '',
        endYmd2: '',
        datediff: 'N',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      saveType: 'POST',
      isSave: false,
      saveUrl: '',
      period1: [],
      period2: [],
      colorItems: [
        { stepperMstCd: '1', stepperMstNm: '건강검진 한번도 받지 않음.', colorClass: 'gray-5' },
        { stepperMstCd: '2', stepperMstNm: '검진주기를 입력하세요.', colorClass: 'blue' },
        { stepperMstCd: '3', stepperMstNm: '1주일 내 검진예정일 도래', colorClass: 'orange' },
        { stepperMstCd: '4', stepperMstNm: '검진지연', colorClass: 'red' },
        { stepperMstCd: '5', stepperMstNm: '', colorClass: 'green-custom' },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.hea.checkup.result.status.url;
      this.saveUrl = transactionConfig.hea.checkup.status.save.url;

      this.getList();
    },
    getList() {
      if (this.period1) {
        this.searchParam.startYmd1 = this.period1[0];
        this.searchParam.endYmd1 = this.period1[1];
      } else {
        this.searchParam.startYmd1 = '';
        this.searchParam.endYmd1 = '';
      }
      if (this.period2) {
        this.searchParam.startYmd2 = this.period2[0];
        this.searchParam.endYmd2 = this.period2[1];
      } else {
        this.searchParam.startYmd2 = '';
        this.searchParam.endYmd2 = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        if (this.searchParam.datediff == 'Y') {
          this.grid.data = this.$_.filter(_result.data, { checkupComeDate: '3' });
        } else {
          this.grid.data = _result.data;
        }
      },);
    },
    saveCycle() {
      let selectData = this.$refs['table'].selected;
      let isConti = true;
      let checkItem = ['checkupCycleCd']
      this.$_.forEach(selectData, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      }); 
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '필수 입력값을 입력해 주세요. [검진주기]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    linkClick(row) {
      this.detailPopup(row);
    },
    detailPopup(result) {
      this.popupOptions.target = () => import(`${"./checkUpResultDetail.vue"}`);
      this.popupOptions.title = '건강검진 결과 상세';
      this.popupOptions.width = '70%';
      this.popupOptions.isFull = false;
      this.popupOptions.param = {
        heaCheckupResultId: result ? result.heaCheckupResultId : '',
        checkTypeName: result ? result.checkTypeName : '',
        checkupDate: result ? result.recentCheckupDate : '',
        checkupTypeCd: result ? result.checkupTypeCd : '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data.checkupComeDate }) || this.$_.find(this.colorItems, { stepperMstCd: data.checkupComeDate })
        return color ? color.codeName || (color.stepperMstNm == '' ? data.diffdate + '일 남음' : color.stepperMstNm) : '';
      } else {
        return ''
      }
    },
  }
};
</script>
